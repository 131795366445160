import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { addSelectedIndex, deleteState, resetPagInfo, resetState, setPagInfo, setSelectedIndex, setState } from '../utils';
import FacturaAPI from '../../api/apis/FacturaAPI';

    //status: 'idle' | 'loading' | 'failed';
    const initialState = {
        facturasPendientes:[],
        indexfacturaPendSelected: -1,
        facturasSeleccionadas:[],
        status: {
            facturasPendientes:'idle'
        },
        error: undefined
    };

    //Generate AsyncThunk
    export const getFacturasPendientesByEstudiante = createAsyncThunk('factura/getFacturasPendientesByEstudiante', async (codigoEstudiante) => {
        return FacturaAPI.getFacturasByEstudiante(codigoEstudiante);
    });


    //Generate Slice
    export const facturaSlice = createSlice({
        name: 'factura',
        initialState,
        reducers: {
            setFacturasPendientes: setState('facturasPendientes'),
            deleteFacturasPendientes: deleteState('facturasPendientes',[]),
            setFacturaPendienteSelected: setSelectedIndex('indexfacturaPendSelected','facturasPendientes', (item,payload)=> item?.codigoFactura === payload?.codigoFactura),
            addFacturaSelected: addSelectedIndex('facturasSeleccionadas','facturasPendientes', (item,payload)=> item?.codigoFactura === payload?.codigoFactura),
            resetState: resetState(initialState)
        },
        extraReducers: (builder) => {
          builder
          .addCase(getFacturasPendientesByEstudiante.pending, (state) => {
            state.status.facturasPendientes = 'loading';
            state.error = undefined;
          })
          .addCase(getFacturasPendientesByEstudiante.fulfilled, (state, action) => {
            state.status.facturasPendientes = 'idle';
            state.error = undefined;
            state.facturasPendientes = action.payload;
            state.indexfacturaPendSelected = -1;
            state.facturasSeleccionadas = [];
          })
          .addCase(getFacturasPendientesByEstudiante.rejected, (state, action)=>{
            state.status.facturasPendientes = 'failed';
            state.error = action?.error?.message;
          })

        },
      });

      //Generate Actions
      export const FacturaActions = {
        setFacturasPendientes: facturaSlice.actions.setFacturasPendientes,
        resetState: facturaSlice.actions.resetState,
        deleteFacturasPendientes: facturaSlice.actions.deleteFacturasPendientes,
        addFacturaSelected: facturaSlice.actions.addFacturaSelected,
        getFacturasPendientesByEstudiante: getFacturasPendientesByEstudiante,
        setFacturaPendienteSelected: facturaSlice.actions.setFacturaPendienteSelected

      };

      //Generate selectors
      export const FacturaSelectors = {
        facturasPendientes:(state) => state.factura.facturasPendientes,
        facturasSeleccionadas: (state) => state.factura.facturasSeleccionadas,
        facturaPendienteSelected:(state) => state.factura.facturasPendientes[state.factura.indexfacturaPendSelected],
        status :{
          facturasPendientes:(state) => state.factura.status.facturasPendientes
        },
        error: (state) => state.factura.error
      }

      export default facturaSlice.reducer;