import { AccesoActions } from "../reducers/AccesoReducer";
import { EstudianteActions } from "../reducers/EstudianteReducer";
import { FacturaActions } from "../reducers/FacturaReducer";
import { GrupoActions } from "../reducers/GrupoReducer";
import { InscripcionActions } from "../reducers/InscripcionReducer";
import { PeriodoAcademicoActions } from "../reducers/PeriodoAcademicoReducer";
import { ProfesorActions } from "../reducers/ProfesorReducer";
import { RegistroAsistenciaActions } from "../reducers/RegistroAsistenciaReducer";
import { UsuarioActions } from "../reducers/UsuarioReducer";

const { useStore } = require("react-redux");
const { store } = require("../store");

const useResetState = () => {

    const { dispatch, getState } = useStore(store);

    const resetState = () => {
        dispatch(UsuarioActions.resetState());
        dispatch(UsuarioActions.resetState());
        dispatch(AccesoActions.resetState());
        dispatch(EstudianteActions.resetState());
        dispatch(FacturaActions.resetState());
        dispatch(GrupoActions.resetState());
        dispatch(InscripcionActions.resetState());
        dispatch(PeriodoAcademicoActions.resetState());
        dispatch(ProfesorActions.resetState());
        dispatch(RegistroAsistenciaActions.resetState());
        dispatch(RegistroAsistenciaActions.resetState());
    };
        
    return {resetState};
}

export default useResetState;