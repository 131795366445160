import { getJsonFromKeys } from "../../../utils";

export function setState(columnName){
    return (state, action)=>{
        state[columnName] = action?.payload;
    }
}

export function deleteState(columnName, initialValue=undefined){
    return (state, action)=>{
        state[columnName] = initialValue;
    }
}

export function resetState(initialState=undefined){
    return (state, action)=>{
        Object.keys(state).forEach( key => {
            state[key] = initialState[key];
        });
    }
}

export function setSelectedIndex(indexColumnName,listColumnName, finder=(item, payload)=>{}){
    return (state, action)=>{
      
        if(typeof action?.payload === 'object'){

          let list = listColumnName instanceof Array ? getJsonFromKeys(state, listColumnName) : state[listColumnName];
  
          let index = list.findIndex(item =>  finder(item, action?.payload));//item?.usuario == action?.payload?.usuario );//state[listColumnName].findIndex(item =>  finder(item, action?.payload));//item?.usuario == action?.payload?.usuario );
  
          if(index >= 0){
            state[indexColumnName] = index;
          }
        }
  
        if(typeof action?.payload === 'number'){
          state[indexColumnName] = action?.payload;
        }
      }
}

export function setSelectedId(idColumnName,listColumnName, valueSelector=(item)=>{}, finder=(item, payload)=>{}){
  return (state, action)=>{
    
      if(typeof action?.payload === 'object'){

        let list = listColumnName instanceof Array ? getJsonFromKeys(state, listColumnName) : state[listColumnName];

        let item = list.find(item =>  finder(item, action?.payload));//item?.usuario == action?.payload?.usuario );//state[listColumnName].findIndex(item =>  finder(item, action?.payload));//item?.usuario == action?.payload?.usuario );

        if(item != undefined){
          state[idColumnName] = valueSelector(item);
        }
      }

      if(typeof action?.payload === 'number'){
        state[idColumnName] = action?.payload;
      }
    }
}

export function addSelectedIndex(indexColumnName,listColumnName, finder=(item, payload)=>{}){
    return (state, action)=>{
      
        if(typeof action?.payload === 'object'){
  
          let index = state[listColumnName].findIndex(item =>  finder(item, action?.payload));//item?.usuario == action?.payload?.usuario );
  
          if(index >= 0){
            state[indexColumnName].push(index);
          }
        }
  
        if(typeof action?.payload === 'number'){
          state[indexColumnName].push(action?.payload);
        }
      }
}

export function setPagInfo(pagInfoColumnName,columnReset, resetValue){
    return (state, action)=>{
        state.pagInfo[pagInfoColumnName] = { ...state.pagInfo[pagInfoColumnName], ...action?.payload };
        state[columnReset] = resetValue;
    }
}

export function resetPagInfo(pagInfoColumnName, columnReset, resetValue){
    return (state, action)=>{
        state.pagInfo[pagInfoColumnName].page = 0;
        state[columnReset] = resetValue;
    }
}