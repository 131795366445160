
export const ResponseErrorHandler = {
    error: (errorCode,mensaje) =>({
        success:false,
        mensaje: mensaje||'Recurso no pudo procesar su solicitud, debido a un error interno.',
        errorCode
    }), 
    resolve: async function(response){

        let body = await response.text();
        
        //Verifico si se envió algún mensaje de error en el body
        if(body.trim().length > 0){
            
            let json = JSON.parse(body);

            if(!!json?.mensaje){
                return this.error(response?.status, json.mensaje);
            }

            if(!!json?.errors){
    
                let isEmpty = Object.keys(json?.errors).length <= 0;

                if(!isEmpty){
                    return this.error(response?.status, Object.keys(json?.errors).map( key => json?.errors[key] ).join('\n'));
                }

                if(!!json?.mensajeGeneral){
                    return this.error(response?.status, json?.mensajeGeneral)
                }
            }

            return this.error(response?.status)
        }

        //Si no se encontró ningún mensaje en el body proceso a retornar un error genérico en base al código de error de la solicitud.

        switch (response?.status) {
            case 400: {return this.error(response?.status, "La llamada al servidor no fue realizada de manera correcta.");}
            case 401: {return this.error(response?.status, "Autenticación con el servidor requerida.");}
            case 403: {return this.error(response?.status, "Usted no tiene autorización al recurso solicitado.");}
            case 404: {return this.error(response?.status, "No se encontró al URL solicitada.");}
            case 405: {return this.error(response?.status, "El método solicitado no esta disponible.");}
            case 406: {return this.error(response?.status, "El recurso invocado no puede generar el tipo de contenido solicitado.");}
            case 408: {return this.error(response?.status, "Tiempo de espera del servidor agotado.");}
            case 415: {return this.error(response?.status, "El recurso invocado no admite el tipo del contenido solicitado.");}
            default: { 
                if(response?.statusText != undefined && response?.statusText?.trim() !== ''){
                    return this.error(response?.status, response?.statusText);
                }
                return this.error(response?.status);
            }
        }

    }
}

export const ExceptionHandler = {
    error: (errorCode,mensaje) =>({
        success:false,
        mensaje: mensaje||'Error al intentar realizar la llamada al API solicitada.',
        errorCode
    }), 
    resolve: async function(error){
        switch(error.message){
            case 'Network request failed': {return this.error(undefined, "Servidor de aplicaciones no disponible, favor intentar mas tarde.");}
            default: { 
                return this.error(undefined);
            }
        }
    }
}

export const ResponseHandler = {

    resolve: async function(response){

        try {
            response = await response;
        } catch (error) {
            let exception = await ExceptionHandler.resolve(error);
            return Promise.reject(Error(exception.mensaje));
        }

        if(!response?.ok){
            let error = await ResponseErrorHandler.resolve(response);
            if(error?.errorCode == 403){
                ErrorListener.notifyAll(error);
            }
            return Promise.reject(Error(error.mensaje));
        }

        let dataString = await response.text();

        if(dataString.trim().length  === 0){
            return Promise.resolve(null);//Promise.reject(Error("Error: La petición fue exitosa pero en servidor no devolvió ningún dato."));
        }

        let dataSerialized;

        try {
            dataSerialized = JSON.parse(dataString);
            if(dataSerialized?.success == false){
                return Promise.reject(Error(dataSerialized?.mensaje??"Error"));
            }
        } catch (error) {
            dataSerialized = dataString;
        }

        return Promise.resolve(dataSerialized);
    }
}

export const ErrorListener = {
    listeners:[],
    addListener(listener=()=>{}){
        this.listeners.push(listener)
    },
    removeListener(listener){
        this.listeners.filter( list => list !== listener )
    },
    notifyAll(data){
        this.listeners.forEach( listener => listener(data) )
    }
}