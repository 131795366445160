import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import ProfesorAPI from '../../api/apis/ProfesorAPI';
    import { deleteState, resetState, setState } from '../utils';

    //status: 'idle' | 'loading' | 'failed';
    const initialState = {
        profesores:[],
        status: {
            profesores:'idle'
        },
        error: undefined
    };

    //Generate AsyncThunk
    export const getAllProfesores = createAsyncThunk('profesor/getAllProfesores', async () => {
        return ProfesorAPI.getAllProfesores();
    });


    //Generate Slice
    export const profesorSlice = createSlice({
        name: 'profesor',
        initialState,
        reducers: {
            setProfesores: setState('profesores'),
            deleteProfesores: deleteState('profesores',[]),
            resetState: resetState(initialState)
        },
        extraReducers: (builder) => {
          builder
          .addCase(getAllProfesores.pending, (state) => {
            state.status.profesores = 'loading';
            state.error = undefined;
          })
          .addCase(getAllProfesores.fulfilled, (state, action) => {
            state.status.profesores = 'idle';
            state.error = undefined;
            state.profesores = action.payload;
          })
          .addCase(getAllProfesores.rejected, (state, action)=>{
            state.status.profesores = 'failed';
            state.error = action?.error?.message;
          })

        },
      });

      //Generate Actions
      export const ProfesorActions = {
        setProfesores: profesorSlice.actions.setProfesores,
        resetState: profesorSlice.actions.resetState,
        deleteProfesores: profesorSlice.actions.deleteProfesores,
        getAllProfesores: getAllProfesores

      };

      //Generate selectors
      export const ProfesorSelectors = {
        profesores:(state) => state.profesor.profesores,
        status :{
          profesores:(state) => state.profesor.status.profesores
        },
        error: (state) => state.profesor.error
      }

      export default profesorSlice.reducer;