import {configureStore, ThunkAction, Action, combineReducers} from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist'; 
import thunk from 'redux-thunk';

import UsuarioReducer from './reducers/UsuarioReducer';
import EstudianteReducer from './reducers/EstudianteReducer';
import ProfesorReducer from './reducers/ProfesorReducer';
import GrupoReducer from './reducers/GrupoReducer';
import PeriodoAcademicoReducer from './reducers/PeriodoAcademicoReducer';
import FacturaReducer from './reducers/FacturaReducer';
import AccesoReducer from './reducers/AccesoReducer';
import InscripcionReducer from './reducers/InscripcionReducer';
import RegistroAsistenciaReducer from './reducers/RegistroAsistenciaReducer';
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage:storage
}

const usuarioPersistConfig = {
  key: 'usuario',
  storage:storage
}

const rootReducer = combineReducers({
  usuario: persistReducer(usuarioPersistConfig, UsuarioReducer),
  estudiante: EstudianteReducer,
  profesor: ProfesorReducer,
  grupo: GrupoReducer,
  periodoAcademico: PeriodoAcademicoReducer,
  factura: FacturaReducer,
  acceso: AccesoReducer,
  inscripcion: InscripcionReducer,
  registroAsistencia: RegistroAsistenciaReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown,  Action<string>>;