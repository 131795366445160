import { client } from '../client';

const PATH = 'factura';

const ENDPOINTS = {
  SAVE: `${PATH}/save`,
  SAVE_WITH_DETAILS: `${PATH}/savewithdetails`,
  CALCULAR_MONTO_ASISTENCIA: `${PATH}/recalcularmontoasistencia`,
  CALCULAR_MONTO_ASISTENCIA_FACTURAS: `${PATH}/recalcularmontoasistencia/estudiante`,
  GET_FACTURAS_PENDIENTES_BY_ESTUDIANTE: `${PATH}/facturapendiente/estudiante`,
  GET_FACTURAS_PENDIENTES_NOT_CUOTA: `${PATH}/facturapendiente/notcuota/estudiante`,
  GET_FACTURAS_VENCIDA: `${PATH}/facturasvencida`,
  GET_FACTURAS_WITH_PAGO: `${PATH}/facturaswithpago/estudiante`,
  GET_FACTURAS_WITHOUT_PAGO: `${PATH}/facturaswithoutpago/estudiante`,
  GET_BY_ID: `${PATH}/id`,
  GET_EXISTE_FACTURA_VENCIDA_ESTUDIANTE: `${PATH}/existefacturavencida/estudiante`,
  GET_FACTURAS_VENCIDAS_ESTUDIANTE: `${PATH}/facturasvencida/estudiante`,
  GENERAR_CUOTA: `${PATH}/generarcuota/estudiante`
};

const FacturaAPI = {
  async insertarFactura(factura = {}) {
    return client.requestPost(ENDPOINTS.SAVE, factura);
  },

  async insertarFacturaWithDetail(factura = {}) {
    return client.requestPost(ENDPOINTS.SAVE_WITH_DETAILS, factura);
  },

  async calcularMontoAsistenciaFactura(factura = { codigoFactura: undefined }) {
    return client.requestPost(ENDPOINTS.CALCULAR_MONTO_ASISTENCIA, factura);
  },

  async calcularMontoAsistenciaFacturas(params={}) {
    return client.requestPost(ENDPOINTS.CALCULAR_MONTO_ASISTENCIA_FACTURAS, params);
  },

  async getFacturasByEstudiante(param = {}) {
    return client.requestWithParams(ENDPOINTS.GET_FACTURAS_PENDIENTES_BY_ESTUDIANTE, param);
  },

  async getFacturasNotCuotaByEstudiante(params = {}) {
    return client.requestWithParams(ENDPOINTS.GET_FACTURAS_PENDIENTES_NOT_CUOTA, params);
  },
  async getFacturasVencida(params = {}) {
    return client.requestWithParams(ENDPOINTS.GET_FACTURAS_VENCIDA, params);
  },
  async getFacturasWithPago(params = {}) {
    return client.requestWithParams(ENDPOINTS.GET_FACTURAS_WITH_PAGO, params);
  },

  async getFacturasWithOutPago(params = {}) {
    return client.requestWithParams(ENDPOINTS.GET_FACTURAS_WITHOUT_PAGO, params);
  },

  async getById(params = {}) {
    return client.requestWithParams(ENDPOINTS.GET_BY_ID, params);
  },

  async getExisteFacturaVencEstudiante(params = {}) {
    return client.requestWithParams(ENDPOINTS.GET_EXISTE_FACTURA_VENCIDA_ESTUDIANTE, params);
  },

  async getFacturasVencEstudiante(params = {}) {
    return client.requestWithParams(ENDPOINTS.GET_FACTURAS_VENCIDAS_ESTUDIANTE, params);
  },

  async generarCuotas(props = {codigoCompania: undefined, codigoEstudiante: undefined,  cantidadCuota: undefined, usuario: undefined,  fechaEvaluar: undefined}) {
    return client.requestPost(ENDPOINTS.GENERAR_CUOTA, props);
  },

};

export default FacturaAPI;