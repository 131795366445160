import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import PeriodoAcademicoAPI from '../../api/apis/PeriodoAcademicoAPI';
    import { deleteState, resetState, setState } from '../utils';

    //status: 'idle' | 'loading' | 'failed';
    const initialState = {
        periodosAcademicos:[],
        status: {
            periodosAcademicos:'idle'
        },
        error: undefined
    };

    //Generate AsyncThunk
    export const getAllPeriodosActivos = createAsyncThunk('periodoAcademico/getAllPeriodosActivos', async () => {
        return PeriodoAcademicoAPI.getAllPeriodosAcademicosActivos();
    });


    //Generate Slice
    export const periodoAcademicoSlice = createSlice({
        name: 'periodoAcademico',
        initialState,
        reducers: {
            setPeriodosAcademicos: setState('periodosAcademicos'),
            deletePeriodosAcademicos: deleteState('periodosAcademicos',[]),
            resetState: resetState(initialState)
        },
        extraReducers: (builder) => {
          builder
          .addCase(getAllPeriodosActivos.pending, (state) => {
            state.status.periodosAcademicos = 'loading';
            state.error = undefined;
          })
          .addCase(getAllPeriodosActivos.fulfilled, (state, action) => {
            state.status.periodosAcademicos = 'idle';
            state.error = undefined;
            state.periodosAcademicos = action.payload;
          })
          .addCase(getAllPeriodosActivos.rejected, (state, action)=>{
            state.status.periodosAcademicos = 'failed';
            state.error = action?.error?.message;
          })

        },
      });

      //Generate Actions
      export const PeriodoAcademicoActions = {
        setPeriodosAcademicos: periodoAcademicoSlice.actions.setPeriodosAcademicos,
        resetState: periodoAcademicoSlice.actions.resetState,
        deletePeriodosAcademicos: periodoAcademicoSlice.actions.deletePeriodosAcademicos,
        getAllPeriodosActivos: getAllPeriodosActivos
      };

      //Generate selectors
      export const PeriodoAcademicoSelectors = {
        periodosAcademicos:(state) => state.periodoAcademico.periodosAcademicos,
        status :{
          periodosAcademicos:(state) => state.periodoAcademico.status.periodosAcademicos
        },
        error: (state) => state.periodoAcademico.error
      }

      export default periodoAcademicoSlice.reducer;