import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
    import { deleteState, resetState, setState } from '../utils';
import InscripcionAPI from '../../api/apis/InscripcionAPI';

    //status: 'idle' | 'loading' | 'failed';
    const initialState = {
        grupoEstudiantesActual:[],
        status: {
            grupoEstudiantesActual:'idle'
        },
        error: undefined
    };

    //Generate AsyncThunk
    export const getEstudiantesByGrupo = createAsyncThunk('inscripcion/getEstudiantesByGrupo', async (codigoGrupo) => { 
        return InscripcionAPI.getListadoEsudiantesByGrupo(codigoGrupo);
    });


    //Generate Slice
    export const inscripcionSlice = createSlice({
        name: 'inscripcion',
        initialState,
        reducers: {
            setGrupoEstudiantesActual: setState('grupoEstudiantesActual'),
            deleteGrupoEstudiantesActual: deleteState('grupoEstudiantesActual',[]),
            resetState: resetState(initialState)
        },
        extraReducers: (builder) => {
          builder
          .addCase(getEstudiantesByGrupo.pending, (state) => {
            state.status.grupoEstudiantesActual = 'loading';
            state.error = undefined;
          })
          .addCase(getEstudiantesByGrupo.fulfilled, (state, action) => {
            state.status.grupoEstudiantesActual = 'idle';
            state.error = undefined;
            state.grupoEstudiantesActual = action.payload;
          })
          .addCase(getEstudiantesByGrupo.rejected, (state, action)=>{
            state.status.grupoEstudiantesActual = 'failed';
            state.error = action?.error?.message;
          })

        },
      });

      //Generate Actions
      export const InscripcionActions = {
        setGrupoEstudiantesActual: inscripcionSlice.actions.setGrupoEstudiantesActual,
        resetState: inscripcionSlice.actions.resetState,
        deleteGrupoEstudiantesActual: inscripcionSlice.actions.deleteGrupoEstudiantesActual,
        getEstudiantesByGrupo: getEstudiantesByGrupo
      };

      //Generate selectors
      export const InscripcionSelectors = {
        grupoEstudiantesActual:(state) => state.inscripcion.grupoEstudiantesActual,
        status :{
          grupoEstudiantesActual:(state) => state.inscripcion.status.grupoEstudiantesActual
        },
        error: (state) => state.inscripcion.error
      }

      export default inscripcionSlice.reducer;