import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UsuarioAPI from '../../api/apis/UsuarioAPI';
import { deleteState, resetState, setSelectedId, setSelectedIndex } from '../utils';
import { sumarFecha } from '../../../utils';
//import UsuarioService from '../../Services/UsuarioService';
import AuthAPI from '../../api/apis/AuthAPI'

//status: 'idle' | 'loading' | 'failed';
const initialState = {
  data: {},
  usuarios: [],
  indexUserSelected: -1,
  idSucursalSeleccionada: -1,
  status: {
    data: 'idle',
    usuarios: 'idle'
  },
  error: undefined
};

export const autenticarUsuario = createAsyncThunk('usuario/autenticarUsuario', async ({ usuario, password }) => {
  return  AuthAPI.login(usuario, password);//UsuarioAPI.autenticarUsuario(usuario, password);
});

export const refreshToken = createAsyncThunk('usuario/refreshToken', async ({token}) => {
  return  AuthAPI.refreshToken({token});
});

export const getAllUsers = createAsyncThunk('usuario/getAllUsers', async () => {
  return UsuarioAPI.getAllUsers();
});

export const usuarioSlice = createSlice({
  name: 'usuario',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.data = { ...state.data, ...action?.payload };
    },
    deleteUserData: (state, action) => {
      state.data = {};
    },
    setUserSelected: (state, action) => {

      if (typeof action?.payload === 'object') {

        let index = state.usuarios.findIndex(item => item?.usuario == action?.payload?.usuario);

        if (index >= 0) {
          state.indexUserSelected = index;
        }
      }

      if (typeof action?.payload === 'number') {
        state.indexUserSelected = action?.payload;
      }
    },
    setToken: (state, action) =>{
      state.data.token = action?.payload
    },
    deleteUsuarios: deleteState('usuarios', []),
    resetState: resetState(initialState),
    setIdSucursalSelected: setSelectedId('idSucursalSeleccionada', ['data', 'sucursalesUsuario'], (item)=>item?.codigoSucursal, (item, payload) => item?.codigoSucursal === payload?.codigoSucursal)
  },
  extraReducers: (builder) => {
    builder
      .addCase(autenticarUsuario.pending, (state) => {
        state.status.data = 'loading';
        state.error = undefined;
      })
      .addCase(autenticarUsuario.fulfilled, (state, action) => {
        state.status.data = 'idle';
        state.error = undefined;
        state.data = {...action.payload, expireTime: sumarFecha(new Date(), 8, "horas" )};
        
      })
      .addCase(autenticarUsuario.rejected, (state, action) => {
        state.status.data = 'failed';
        state.error = action?.error?.message;
      })
      .addCase(getAllUsers.pending, (state) => {
        state.status.usuarios = 'loading';
        state.error = undefined;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.status.usuarios = 'idle';
        state.error = undefined;
        state.indexUserSelected = -1;
        state.usuarios = action.payload;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.status.usuarios = 'failed';
        state.indexUserSelected = -1;
        state.error = action?.error?.message;
      })
      .addCase(refreshToken.pending, (state) => {
        state.status.data = 'loading';
        state.error = undefined;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.status.data = 'idle';
        state.error = undefined;
        state.data = {...state.data, token: action.payload};
        
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.status.data = 'failed';
        state.error = action?.error?.message;
      });
  },
});

export const UsuarioActions = {
  setUserData: usuarioSlice.actions.setUserData,
  deleteUserData: usuarioSlice.actions.deleteUserData,
  deleteUsuarios: usuarioSlice.actions.deleteUsuarios,
  resetState: usuarioSlice.actions.resetState,
  setUserSelected: usuarioSlice.actions.setUserSelected,
  setSucursalSelected: usuarioSlice.actions.setIdSucursalSelected,
  autenticarUsuario: autenticarUsuario,
  refreshToken: refreshToken,
  setToken: usuarioSlice.actions.setToken,
  getAllUsers: getAllUsers
};

export const UsuarioSelectors = {
  data: (state) => state.usuario.data,
  token: (state) => state.usuario?.data?.token,
  usuarios: (state) => state.usuario.usuarios,
  usuarioSeleccionado: (state) => state.usuario.usuarios[state.usuario.indexUserSelected],
  statusData: (state) => state.usuario.status.data,
  statusUsuarios: (state) => state.usuario.status.usuarios,
  error: (state) => state.usuario.error,
  detallesAcceso: (state) => state.usuario.data?.detallesAcceso,
  sucursalesUsuario: (state) => state.usuario.data?.sucursalesUsuario,
  sucursalDefecto: (state) => state.usuario.data?.sucursalesUsuario?.find(sucursal => sucursal?.defecto),
  sucursalSeleccionada: (state) => {

    let idSelected = state.usuario.idSucursalSeleccionada;

    if (idSelected >= 0) {
      return UsuarioSelectors.sucursalesUsuario(state)?.find(item => item?.codigoSucursal == idSelected);
    }

    let sucursalSeleccionada = UsuarioSelectors.sucursalDefecto(state);

    if (sucursalSeleccionada != undefined && sucursalSeleccionada != null) {
      return sucursalSeleccionada;
    }

    let sucursalesUsuario = UsuarioSelectors.sucursalesUsuario(state);
    let primerSucursal = sucursalesUsuario ? sucursalesUsuario[0] : undefined;


    return primerSucursal;
  }

}

export default usuarioSlice.reducer;