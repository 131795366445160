import { client } from '../client';

const PATH = 'grupo';

const ENDPOINTS = {
  SAVE: `${PATH}/save`,
  UPDATE: `${PATH}/update`,
  GET_BY_ID: codigoGrupo => `${PATH}/id/${codigoGrupo}`,
  GET_ALL: `${PATH}/all`,
  GET_ALL_ACTIVE: `${PATH}/all/active`,
  GET_ALL_ACTIVE_INSCRIPCION: `${PATH}/all/inscripcion/active`,
  GET_ALL_BY_PERIODO: `${PATH}/all/periodo`
};


const GrupoAPI = {
  async insertarGrupo(grupo = {
    codigoProfesor: undefined,
    descripcion: undefined,
    edadMaxima: undefined,
    edadMinima: undefined,
    estatus: undefined,
    detallesHorarioGrupo: undefined
  }) {
    return client.requestPost(ENDPOINTS.SAVE, grupo);
  },

  async updateGrupo(grupo = {
    codigoProfesor: undefined,
    descripcion: undefined,
    edadMaxima: undefined,
    edadMinima: undefined,
    estatus: undefined
  }) {
    return client.requestPut(ENDPOINTS.UPDATE, grupo);
  },

  async getGrupoById(codigoGrupo) {
    return client.requestGet(ENDPOINTS.GET_BY_ID(codigoGrupo));
  },

  async getAllGrupos(params = {}) {
    return client.requestWithParams(ENDPOINTS.GET_ALL, params);
  },

  async getAllGruposActivo(params = {}) {
    return client.requestWithParams(ENDPOINTS.GET_ALL_ACTIVE, params);
  },

  async getAllGruposActivoInscripcion(params = {}) {
    return client.requestWithParams(ENDPOINTS.GET_ALL_ACTIVE_INSCRIPCION, params);
  },


  async getAllGrupoByPeriodo(params = {}) {
    return client.requestWithParams(ENDPOINTS.GET_ALL_BY_PERIODO, params);
  }
};

export default GrupoAPI;