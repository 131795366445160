import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import EstudianteAPI from '../../api/apis/EstudianteAPI';
import { deleteState, resetPagInfo, resetState, setPagInfo, setSelectedIndex, setState } from '../utils';

//status: 'idle' | 'loading' | 'failed';
const initialState = {
  estudiantes:[],
  indexEstudianteSelected: -1,
  pagInfo:{
    estudiantes:{
      page:0,
      size:16,
      filtro:''
    }
  },
  status: {
    estudiantes:'idle'
  },
  error: undefined
};

export const getEstudiantesPaginado = createAsyncThunk('estudiante/getEstudiantesPaginado', async (props, {getState}) => {

  const { page, size, filtro } = EstudianteSelectors.pagInfo.estudiantes(getState());
  return EstudianteAPI.getAllPagined(page, size, filtro);

});

export const estudianteSlice = createSlice({
  name: 'estudiante',
  initialState,
  reducers: {
    setEstudiantes: setState('estudiantes'),
    deleteEstudiantes: deleteState('estudiantes',[]),
    setEstudianteSelected: setSelectedIndex('indexEstudianteSelected','estudiantes', (item,payload)=> item?.codigoEstudiante === payload?.codigoEstudiante),
    setPagInfoEstudiantes: setPagInfo('estudiantes','estudiantes',[]),
    resetPagInfoEstudiantes: resetPagInfo('estudiantes','estudiantes',[]),
    resetState: resetState(initialState)
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEstudiantesPaginado.pending, (state) => {
        state.status.estudiantes = 'loading';
        state.error = undefined;
      })
      .addCase(getEstudiantesPaginado.fulfilled, (state, action) => {
        
        state.status.estudiantes = 'idle';
        state.error = undefined;
        state.estudiantes = state.estudiantes.concat(action.payload);

        if(action?.payload?.length > 0){ state.pagInfo.estudiantes.page += 1; }
        if(state.estudiantes.length <= 0){ state.indexEstudianteSelected = -1; }

      })
      .addCase(getEstudiantesPaginado.rejected, (state, action)=>{
        state.status.estudiantes = 'failed';
        state.error = action?.error?.message;
      });
  },
});

export const EstudianteActions = {
  setEstudiantes: estudianteSlice.actions.setEstudiantes, 
  resetState: estudianteSlice.actions.resetState,
  deleteEstudiantes: estudianteSlice.actions.deleteEstudiantes,
  setEstudianteSelected: estudianteSlice.actions.setEstudianteSelected,
  setPagInfoEstudiantes: estudianteSlice.actions.setPagInfoEstudiantes,
  resetPagInfoEstudiantes: estudianteSlice.actions.resetPagInfoEstudiantes,
  getEstudiantesPaginado: getEstudiantesPaginado
};

export const EstudianteSelectors = {
  estudiantes: (state) =>  state.estudiante.estudiantes,
  estudianteSeleccionado: (state) => state.estudiante.estudiantes[state.estudiante.indexEstudianteSelected],
  pagInfo: { 
    estudiantes: (state) => state.estudiante.pagInfo.estudiantes
  },
  status :{
    fetchEstudiantes: (state) => state.estudiante.status.estudiantes
  },
  error: (state) => state.estudiante.error
}

export default estudianteSlice.reducer;