import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import GrupoAPI from '../../api/apis/GrupoAPI';
    import { deleteState, resetState, setSelectedIndex, setState } from '../utils';

    //status: 'idle' | 'loading' | 'failed';
    const initialState = {
        grupos:[],
        indexGrupoSelected: -1,
        status: {
            grupos:'idle'
        },
        error: undefined
    };

    //Generate AsyncThunk
    export const getAllGrupos = createAsyncThunk('grupo/getAllGrupos', async (props) => {
        return GrupoAPI.getAllGrupos();
    });
    

    //Generate Slice
    export const grupoSlice = createSlice({
        name: 'grupo',
        initialState,
        reducers: {
            setGrupos: setState('grupos'),
            deleteGrupos: deleteState('grupos',[]),
            setGrupoSelected: setSelectedIndex('indexGrupoSelected','grupos', (item,payload)=> item?.codigoGrupo === payload?.codigoGrupo),
            resetState: resetState(initialState)
        },
        extraReducers: (builder) => {
          builder
          .addCase(getAllGrupos.pending, (state) => {
            state.status.grupos = 'loading';
            state.error = undefined;
          })
          .addCase(getAllGrupos.fulfilled, (state, action) => {
            state.status.grupos = 'idle';
            state.error = undefined;
            state.grupos = action.payload;
          })
          .addCase(getAllGrupos.rejected, (state, action)=>{
            state.status.grupos = 'failed';
            state.error = action?.error?.message;
          })

        },
      });

      //Generate Actions
      export const GrupoActions = {
        setGrupos: grupoSlice.actions.setGrupos,
        resetState: grupoSlice.actions.resetState,
        deleteGrupos: grupoSlice.actions.deleteGrupos,
        getAllGrupos: getAllGrupos,
        setGrupoSelected: grupoSlice.actions.setGrupoSelected
      };

      //Generate selectors
      export const GrupoSelectors = {
        grupos:(state) => state.grupo.grupos,
        grupoSeleccionado: (state)=>state.grupo.grupos[state.grupo.indexGrupoSelected],
        status :{
          grupos:(state) => state.grupo.status.grupos
        },
        error: (state) => state.grupo.error
      }

      export default grupoSlice.reducer;