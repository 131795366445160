import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  deleteState,
  resetPagInfo,
  resetState,
  setPagInfo,
  setSelectedIndex,
  setState,
} from '../utils';
import RegistroAsistenciaAPI from '../../api/apis/RegistroAsistenciaAPI';

//status: 'idle' | 'loading' | 'failed';
const initialState = {
  registrosAsistenciaGrupo: [],
  registrosAsistenciaEstudiante: [],
  pagInfo: {
    registrosAsistenciaGrupo: {
      page: 0,
      size: 16,
      filtro: '',
    },
    registrosAsistenciaEstudiante: {
      page: 0,
      size: 16,
      filtro: '',
    },
  },
  status: {
    registrosAsistenciaGrupo: 'idle',
    registrosAsistenciaEstudiante: 'idle',
  },
  error: undefined,
};

//Generate AsyncThunk
export const getRegistrosAsistenciaGrupoPaginado = createAsyncThunk(
  'registroAsistencia/getRegistrosAsistenciaGrupoPaginado',
  async (props = {codigoGrupo, fechaInicial, fechaFinal}, {getState}) => {
    const {page, size, filtro} =
      RegistroAsistenciaSelectors.pagInfo.registrosAsistenciaGrupo(getState());
    return RegistroAsistenciaAPI.getRegistroAsistenciaByGrupo(
      props.codigoGrupo,
      props.fechaInicial,
      props.fechaFinal,
    );
  },
);

export const getRegistrosAsistenciaEstudiantePaginado = createAsyncThunk(
  'registroAsistencia/getRegistrosAsistenciaEstudiantePaginado',
  async (props = {codigoEstudiante, fechaInicial, fechaFinal}, {getState}) => {
    const {page, size, filtro} =
      RegistroAsistenciaSelectors.pagInfo.registrosAsistenciaEstudiante(
        getState(),
      );
    return RegistroAsistenciaAPI.getRegistroAsistenciaByEsudiante(
      props.codigoEstudiante,
      props.fechaInicial,
      props.fechaFinal,
    );
  },
);

//Generate Slice
export const registroAsistenciaSlice = createSlice({
  name: 'registroAsistencia',
  initialState,
  reducers: {
    setRegistrosAsistenciaGrupo: setState('registrosAsistenciaGrupo'),
    deleteRegistrosAsistenciaGrupo: deleteState('registrosAsistenciaGrupo', []),
    setRegistrosAsistenciaEstudiante: setState('registrosAsistenciaEstudiante'),
    deleteRegistrosAsistenciaEstudiante: deleteState(
      'registrosAsistenciaEstudiante',
      [],
    ),

    setPagInfoRegAsistGrupo: setPagInfo(
      'registrosAsistenciaGrupo',
      'registrosAsistenciaGrupo',
      [],
    ),
    resetPagInfoRegAsistGrupo: resetPagInfo(
      'registrosAsistenciaGrupo',
      'registrosAsistenciaGrupo',
      [],
    ),

    setPagInfoRegAsistEstudiante: setPagInfo(
      'registrosAsistenciaEstudiante',
      'registrosAsistenciaEstudiante',
      [],
    ),
    resetPagInfoRegAsistEstudiante: resetPagInfo(
      'registrosAsistenciaEstudiante',
      'registrosAsistenciaEstudiante',
      [],
    ),
    resetState: resetState(initialState)
  },
  extraReducers: builder => {
    builder
      .addCase(getRegistrosAsistenciaGrupoPaginado.pending, state => {
        state.status.registrosAsistenciaGrupo = 'loading';
        state.error = undefined;
      })
      .addCase(
        getRegistrosAsistenciaGrupoPaginado.fulfilled,
        (state, action) => {
          state.status.registrosAsistenciaGrupo = 'idle';
          state.error = undefined;
          state.registrosAsistenciaGrupo =
            state.registrosAsistenciaGrupo.concat(action.payload);

          if (action?.payload?.length > 0) {
            state.pagInfo.registrosAsistenciaGrupo.page += 1;
          }
        },
      )
      .addCase(
        getRegistrosAsistenciaGrupoPaginado.rejected,
        (state, action) => {
          state.status.registrosAsistenciaGrupo = 'failed';
          state.error = action?.error?.message;
        },
      )

      .addCase(getRegistrosAsistenciaEstudiantePaginado.pending, state => {
        state.status.registrosAsistenciaEstudiante = 'loading';
        state.error = undefined;
      })
      .addCase(
        getRegistrosAsistenciaEstudiantePaginado.fulfilled,
        (state, action) => {
          state.status.registrosAsistenciaEstudiante = 'idle';
          state.error = undefined;
          state.registrosAsistenciaEstudiante =
            state.registrosAsistenciaEstudiante.concat(action.payload);

          if (action?.payload?.length > 0) {
            state.pagInfo.registrosAsistenciaEstudiante.page += 1;
          }
        },
      )
      .addCase(
        getRegistrosAsistenciaEstudiantePaginado.rejected,
        (state, action) => {
          state.status.registrosAsistenciaEstudiante = 'failed';
          state.error = action?.error?.message;
        },
      );
  },
});

//Generate Actions
export const RegistroAsistenciaActions = {
  setRegistrosAsistenciaGrupo:
    registroAsistenciaSlice.actions.setRegistrosAsistenciaGrupo,
    resetState: registroAsistenciaSlice.actions.resetState,
  deleteRegistrosAsistenciaGrupo:
    registroAsistenciaSlice.actions.deleteRegistrosAsistenciaGrupo,
  getRegistrosAsistenciaPaginado: getRegistrosAsistenciaGrupoPaginado,
  setRegistrosAsistenciaEstudiante:
    registroAsistenciaSlice.actions.setRegistrosAsistenciaEstudiante,
  deleteRegistrosAsistenciaEstudiante:
    registroAsistenciaSlice.actions.deleteRegistrosAsistenciaEstudiante,
  getRegistrosAsistenciaPaginado: getRegistrosAsistenciaEstudiantePaginado,
  setPagInfoRegAsistGrupo:
    registroAsistenciaSlice.actions.setPagInfoRegAsistGrupo,
  resetPagInfoRegAsistGrupo:
    registroAsistenciaSlice.actions.resetPagInfoRegAsistGrupo,
  setPagInfoRegAsistEstudiante:
    registroAsistenciaSlice.actions.setPagInfoRegAsistEstudiante,
  resetPagInfoRegAsistEstudiante:
    registroAsistenciaSlice.actions.resetPagInfoRegAsistEstudiante,
};

//Generate selectors
export const RegistroAsistenciaSelectors = {
  registrosAsistenciaGrupo: state =>
    state.registroAsistencia.registrosAsistenciaGrupo,
  registrosAsistenciaEstudiante: state =>
    state.registroAsistencia.registrosAsistenciaEstudiante,
  status: {
    registrosAsistenciaGrupo: state =>
      state.registroAsistencia.status.registrosAsistenciaGrupo,
    registrosAsistenciaEstudiante: state =>
      state.registroAsistencia.status.registrosAsistenciaEstudiante,
  },
  pagInfo: {
    registrosAsistenciaGrupo: state =>
      state.registroAsistencia.pagInfo.registrosAsistenciaGrupo,
    registrosAsistenciaEstudiante: state =>
      state.registroAsistencia.pagInfo.registrosAsistenciaEstudiante,
  },
  error: state => state.registroAsistencia.error,
};

export default registroAsistenciaSlice.reducer;
