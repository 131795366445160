import { serverConfig } from "../../../conf/serverConfig";

export const headersTemplates ={
    get: async () => ({
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            Authorization:'Bearer ' + await serverConfig.getSecureToken(),
        },
    }),
    post: async ({body, token=undefined})=>({
        method: 'POST',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: 'Bearer ' + (token? token : await serverConfig.getSecureToken())
        },
        body: (typeof body === 'string')? body: JSON.stringify(body),
    }),
    postWithoutToken: async ({body})=>{
        let header = {
            method: 'POST',
            headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
        }

        if(body){
            header.body = (typeof body === 'string')? body: JSON.stringify(body);
        }

        return header;
    },
    postText: async ({body})=>({
        method: 'POST',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'text/plain',
            Authorization: 'Bearer ' + await serverConfig.getSecureToken()
        },
        body: (typeof body === 'string')? body: JSON.stringify(body),
    }),
    put: async ({body})=>{
        let json ={
            method: 'PUT',
            headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization':'Bearer ' + await serverConfig.getSecureToken()
            },
            body: (typeof body === 'string')? body: JSON.stringify(body),
        }
        return json;
    },
    delete: async ({body})=>({
        method: 'DELETE',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: 'Bearer ' + await serverConfig.getSecureToken()
        },
        body: (typeof body === 'string')? body: JSON.stringify(body),
    })
}