import {client} from '../client';

  const PATH = 'periodoacademico';

  const ENDPOINTS = {
    SAVE: `${PATH}/save`,
    UPDATE: `${PATH}/update`,
    DELETE: codigoPeriodoAcademico => `${PATH}/delete/${codigoPeriodoAcademico}`,
    GET_BY_ID: codigoPeriodoAcademico => `${PATH}/id/${codigoPeriodoAcademico}`,
    GET_PERIODO_ACTUAL: `${PATH}/periodoacademicoactual`,
    GET_ALL: `${PATH}/all`,
    GET_ALL_ACTIVE: `${PATH}/all/activo`
  };

  const PeriodoAcademicoAPI = {
    async insertarPeriodoAcademico( periodoAcademico = {} ) {
      return client.requestPost(ENDPOINTS.SAVE, periodoAcademico);
    },

    async updatePeriodoAcademico( periodoAcademico = {} ) {
      return client.requestPut(ENDPOINTS.UPDATE, periodoAcademico );
    },

    async getPeriodoAcademicoById(codigoPeriodoAcademico) {
      return client.requestGet(ENDPOINTS.GET_BY_ID(codigoPeriodoAcademico));
    },

    async getAllPeriodoAcademicos(params={}){
      return client.requestWithParams(ENDPOINTS.GET_ALL, params);
    },

    async getAllPeriodosAcademicosActivos(params={}){
      return client.requestWithParams(ENDPOINTS.GET_ALL_ACTIVE, params);
    },

    async getPeriodoAcademicoActual(params={}){
      return client.requestWithParams(ENDPOINTS.GET_PERIODO_ACTUAL, params);
    },

    async deletePeriodoAcademico(codigoPeriodoAcademico){
        return client.requestDelete(ENDPOINTS.DELETE(codigoPeriodoAcademico),undefined);
    }
  };

  export default PeriodoAcademicoAPI;