//import { UsuarioSelectors } from "../model/redux/reducers/UsuarioReducer";
import AuthAPI from "../model/api/apis/AuthAPI";
import { UsuarioActions, UsuarioSelectors } from "../model/redux/reducers/UsuarioReducer";
import { store } from "../model/redux/store";
import { isEmpty } from "../utils";
// const Crypto = require('react-native-crypto-js');

//const currentToken = ''; 
function getCurrentToken(){
    return UsuarioSelectors.token(store.getState());
}

function decodeToken(token){
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
}

function tokenIsExpired(token){
    
    const decodedToken = decodeToken(token)
    const expire = decodedToken?.exp;
    const currentTime = new Date().getTime() / 1000;

    return currentTime >=  expire;
}

async function refreshToken(token){

    const isExpired = tokenIsExpired(token);

    if(isExpired) {
        return token;
    }

    const decodedToken = decodeToken(token);
    
    const currentTime = new Date().getTime() / 1000;
    const expireTime = decodedToken?.exp;
    const expDiff = expireTime - currentTime;
    
    // console.log('Expiration => ',expDiff);

    if(expDiff <= 3600){
        
        //console.log('entro al refresh')
        //console.log('oldtoken ', token)

        const response = await store.dispatch(UsuarioActions.refreshToken({token}));
        const newToken = response?.payload;

        // console.log('newToken ',newToken)

        return newToken;
    }

    return token;
    
}

export const serverConfig = {
    ip: process.env.NEXT_PUBLIC_SERVER_NAME != undefined ? process.env.NEXT_PUBLIC_SERVER_NAME : 'academynew.ddns.net', //'academynew.ddns.net'
    puerto: process.env.NEXT_PUBLIC_SERVER_PORT_BACK != undefined ? process.env.NEXT_PUBLIC_SERVER_PORT_BACK : 80890,
    isHttps:  process.env.NEXT_PUBLIC_SERVER_OVER_HTTPS != undefined? process.env.NEXT_PUBLIC_SERVER_OVER_HTTPS==='true' : false,
    context:'api', 
    path:'',
    //token: { user:'TWITeRoJ', password:'^Uflttwr08Q8V8$*k&0M@54' },
    token: '',
    setIp(ip){
        this.ip=ip;
    },
    setPuerto(puerto){
        this.puerto = puerto;
    },
    setHttps(isHttps){
        this.isHttps = isHttps;
    },
    configure(ip, puerto, ishttps){
        this.setIp(ip);
        this.setPuerto(puerto);
        this.setHttps(ishttps);
    },

    async getSecureToken(){
        return await refreshToken(getCurrentToken());
    },
    get secureToken(){

        const currentToken = getCurrentToken();
        return currentToken;//bodyparm;
    },
    get server(){
        var protocolo = this.isHttps? 'https' : 'http';
        var server = this.puerto!=null? `${this.ip}:${this.puerto}`:this.ip;
        return `${protocolo}://${server}`;
    }, 
    get serverContext(){
        return `${this.server}/${this.context}`;
    },
    get serverContextPath(){
        if(isEmpty(this.path)){
            return this.serverContext;
        }
        return `${this.serverContext}/${this.path}`        
    },

};
