import {client} from '../client';

const PATH = 'usuario';

const ENDPOINTS = {
  AUTH_USER: `${PATH}/autenticar`,
  REGISTEER_USER: `${PATH}/registrar`,
  UPDATE: `${PATH}/update`,
  GET_BY_ID: usuario => `${PATH}/id/${usuario}`,
  GET_ALL: `${PATH}/all`,
  GET_ALL_ADMINISTRATOR: `${PATH}/all/administrator`,
  DELETE: usuario => `${PATH}/delete/${usuario}`,
  RESET_PASSWORD: `${PATH}/resetpassword`,
  CHANGE_PASSWORD: `${PATH}/changepassword`,
};

const UsuarioAPI = {
  async autenticarUsuario(usuario, password) {
    let body = {usuario, password};
    return client.requestPost(ENDPOINTS.AUTH_USER, body);
  },

  async registrarUsuario({usuario, entidad, sucursalUsuario}) {
    let body = {usuario, entidad, sucursalUsuario};
    return client.requestPost(ENDPOINTS.REGISTEER_USER, body);
  },

  async updateUser(datosUsuario) {
    let body = datosUsuario;
    return client.requestPut(ENDPOINTS.UPDATE, body);
  },

  async getUserById(usuario) {
    return client.requestGet(ENDPOINTS.GET_BY_ID(usuario));
  },

  async getAllUsers(params={}) {
    return client.requestWithParams(ENDPOINTS.GET_ALL, params);
  },

  async getAllAdministratorUsers(params={}) {
    return client.requestWithParams(ENDPOINTS.GET_ALL_ADMINISTRATOR, params);
  },

  async existUser(usuario) {
    let user = await this.getUserById(usuario);

    if (user?.usuario?.toUpperCase() === usuario?.toUpperCase()) {
      return true;
    }

    return false;
  },

  async resetUserPassword(
    params = {
      usuario: undefined,
      password: undefined,
      oldPassword: undefined,
      newPassword: undefined,
    },
  ) {
    return client.requestPost(ENDPOINTS.RESET_PASSWORD, params);
  },

  async changeUserPassword(
    params = {
      usuario: undefined,
      password: undefined,
      oldPassword: undefined,
      newPassword: undefined,
    },
  ) {
    return client.requestPost(ENDPOINTS.CHANGE_PASSWORD, params);
  },
};

export default UsuarioAPI;
