import { useRouter } from "next/router";
import { store } from "../../model/redux/store";
import { useStore } from "react-redux";
import { UsuarioSelectors } from "../../model/redux/reducers/UsuarioReducer";
import { useAppAccess } from "../../utils/security/useAppAccess";

const withAuth = (Component) => {
  const Auth = (props) => {
    const router = useRouter();
    const { getState } = useStore(store);

    const userLogged = UsuarioSelectors.data(getState())?.success;
    const detalleAcceso = UsuarioSelectors.detallesAcceso(getState());
    const { hasAccessToRoute } = useAppAccess(detalleAcceso);

    if (!userLogged && !(router?.route == '/auth/login') && !(router?.route == '/privacypolicy')) {
      router.push("/auth/login", undefined, { shallow: true });
      return <p>Verificando acceso...</p>;
    } else if (hasAccessToRoute(router?.route) || router?.route == '/auth/login' ||
      router?.route == '/privacypolicy' ||
      router?.route == '/auth/access' ||
      router?.route == '/') {
      return <Component {...props} />;
    } else if (router?.pathname == '/404') {
      return <Component {...props} />;
    } else {
      router.push("/auth/access", undefined, { shallow: true });
    }

    // if (userLogged || router?.route == '/auth/login' || router?.route == '/privacypolicy') {
    //   return <Component {...props} />;
    // } else {

    //   router.push("/auth/login", undefined, { shallow: true });
    //   return <p>Verificando acceso...</p>;
    // }
  };

  return Auth;
};

export default withAuth;
