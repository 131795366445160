import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
    import { deleteState, resetState, setState } from '../utils';
import DetalleAccesoAPI from '../../api/apis/DetalleAccesoAPI';

    //status: 'idle' | 'loading' | 'failed';
    const initialState = {
        accesos:[],
        status: {
            accesos:'idle'
        },
        error: undefined
    };

    //Generate AsyncThunk
    export const getAllAccesos = createAsyncThunk('acceso/getAllAccesos', async (props) => {
        return DetalleAccesoAPI.getAllAccesos();
    });


    //Generate Slice
    export const accesoSlice = createSlice({
        name: 'acceso',
        initialState,
        reducers: {
            setAccesos: setState('accesos'),
            deleteAccesos: deleteState('accesos',[]),
            resetState: resetState(initialState)
        },
        extraReducers: (builder) => {
          builder
          .addCase(getAllAccesos.pending, (state) => {
            state.status.accesos = 'loading';
            state.error = undefined;
          })
          .addCase(getAllAccesos.fulfilled, (state, action) => {
            state.status.accesos = 'idle';
            state.error = undefined;
            state.accesos = action.payload;
          })
          .addCase(getAllAccesos.rejected, (state, action)=>{
            state.status.accesos = 'failed';
            state.error = action?.error?.message;
          })

        },
      });

      //Generate Actions
      export const AccesoActions = {
        setAccesos: accesoSlice.actions.setAccesos,
        deleteAccesos: accesoSlice.actions.deleteAccesos,
        resetState: accesoSlice.actions.resetState,
        getAllAccesos: getAllAccesos

      };

      //Generate selectors
      export const AccesoSelectors = {
        accesos:(state) => state.acceso.accesos,
        status :{
          accesos:(state) => state.acceso.status.accesos
        },
        error: (state) => state.acceso.error
      }

      export default accesoSlice.reducer;