import {client} from '../client';

  const PATH = 'inscripcion';

  const ENDPOINTS = {
    SAVE: `${PATH}/save`,
    SAVE_AND_ESTUDIANTE: `${PATH}/save/estudiante`,
    UPDATE: `${PATH}/update`,
    GET_BY_ID: codigoInscripcion => `${PATH}/id/${codigoInscripcion}`,
    GET_BY_GRUPO: codigoGrupo => `${PATH}/listadoestudiante/grupo/${codigoGrupo}`,
    SEND_EMAIL: `${PATH}/enviaremail`,
    TRANSFERENCIA_ESTUDIANTE: `${PATH}/transferenciagrupo`,
    GET_INSCRIPCION_BY_ESTUDIANTE: `${PATH}/listadoinscripcion/estudiante`,
    DELETE_ESTUDIANTE: (codigoCompania, codigoEstudiante, usuario) => `${PATH}/deleteestudiante/compania/${codigoCompania}/estudiante/${codigoEstudiante}/usuario/${usuario}`

  };

  const InscripcionAPI = {
    async insertarInscripcion( inscripcion = {} ) {
      return client.requestPost(ENDPOINTS.SAVE, inscripcion);
    },

    async insertarInscripcionAndEstudiante( inscripcion = {} ) {
      return client.requestPost(ENDPOINTS.SAVE_AND_ESTUDIANTE, inscripcion);
    },

    async updateInscripcion( inscripcion = {} ) {
      return client.requestPut(ENDPOINTS.UPDATE, inscripcion );
    },

    async getInscripcionById(codigoInscripcion) {
      return client.requestGet(ENDPOINTS.GET_BY_ID(codigoInscripcion));
    },

    async deleteEstudiante(codigoCompania, codigoEstudiante, usuario) {
      return client.requestDelete(ENDPOINTS.DELETE_ESTUDIANTE(codigoCompania, codigoEstudiante, usuario));
    },

    async getListadoEsudiantesByGrupo(codigoGrupo){
      return client.requestGet(ENDPOINTS.GET_BY_GRUPO(codigoGrupo));
    },

    async sendEmailInscripcion(inscripcion = {}) {
      return client.requestPost(ENDPOINTS.SEND_EMAIL, inscripcion);
    },

    async transferenciaEstudiante( inscripcion = {} ) {
      return client.requestPost(ENDPOINTS.TRANSFERENCIA_ESTUDIANTE, inscripcion);
    },

    async getListadoInscripcionByEstudiante(params = {}) {
      return client.requestWithParams(ENDPOINTS.GET_INSCRIPCION_BY_ESTUDIANTE, params);
    },
  };

  export default InscripcionAPI;