import {client} from '../client';

const ENDPOINTS = {
  LOGIN: `login`,
  REFRESH_TOKEN: `refresh-token`
};

const AuthAPI = {

  async login(usuario, password) {
    let body = {usuario, password};
    return client.requestPostWithoutToken(ENDPOINTS.LOGIN, body);
  },

  async refreshToken({token}) {
    return client.requestPostWithToken(ENDPOINTS.REFRESH_TOKEN, token, null);
  },

};

export default AuthAPI;
