import {client} from '../client';

const PATH = 'detalleacceso';

const ENDPOINTS = {
  SAVE: `${PATH}/save`,
  UPDATE: `${PATH}/update`,
  DELETE: (usuario, codigoAcceso) =>
    `${PATH}/delete/usuario/${usuario}/codigoacceso/${codigoAcceso}`,
  GET_BY_USER: usuario => `${PATH}/usuario/${usuario}`,
  GET_ALL_ACCESS: `acceso/all`,
};

const DetalleAccesoAPI = {
  async insertarDetalleAcceso(
    detalleAcceso = {
      usuario: undefined,
      codigoAcceso: undefined,
      activo: undefined,
    },
  ) {
    return client.requestPost(ENDPOINTS.SAVE, detalleAcceso);
  },

  async updateDetalleAcceso(
    detalleAcceso = {
      usuario: undefined,
      codigoAcceso: undefined,
      activo: undefined,
    },
  ) {
    return client.requestPut(ENDPOINTS.UPDATE, detalleAcceso);
  },

  async getDetallesAccesoByUser(usuario) {
    return client.requestGet(ENDPOINTS.GET_BY_USER(usuario));
  },

  async deleteDetalleAcceso(usuario, codigoAcceso) {
    return client.requestDelete(ENDPOINTS.DELETE(usuario, codigoAcceso));
  },

  async getAllAccesos() {
    return client.requestGet(ENDPOINTS.GET_ALL_ACCESS);
  },
};

export default DetalleAccesoAPI;
